import React from "react";
import { Copyright, Footer, Nav, Partners } from "../components/partials";
import SEO from "../components/utils/SEO";
import Page from "../components/Page";
import {SectionSupport} from "../components/support";
import {graphql} from "gatsby";
import { useTranslation } from "react-i18next";

const SupportPage = () => {
    const {t} = useTranslation();

    return (
        <Page>
            <SEO title={t('page_title_support')}/>
            <Nav/>
            <SectionSupport />
            <Footer/>
          <Partners />
          <Copyright/>
        </Page>
    );
}

export default SupportPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;