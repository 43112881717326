import styled, { keyframes } from "styled-components";
import {Section} from "../../partials";
import {colors, fonts} from "../../../config";
import { Field } from "formik";

export const StyledSection = styled(Section)`
  padding: 100px 0 40px;

  @media screen and (min-width: 768px) {
    padding: 120px 0 40px;
  }

  @media screen and (min-width: 1200px) {
    padding: 160px 0 40px;
  }
`;

export const StyledTitle = styled.h1`
  font-size: 58px;
  color: ${colors.white};
  line-height: 1.16;
  font-family: ${fonts.roboto};
  margin-bottom: 30px;
  
  @media screen and (max-width: 1200px) {
    font-size: 48px;
  }
  
  @media screen and (max-width: 992px) {
    text-align: center;
  }
  
  @media screen and (max-width: 768px) {
    font-size: 36px;
    margin-bottom: 15px;
  }
`;

export const StyledSubtitle = styled.h3`
  font-family: ${fonts.nunitoSans};
  font-size: 16px;
  line-height: 1.6;
  font-weight: 300;
  color: ${colors.white};
`;

export const ImgWrapper = styled.div`
  max-width: 560px;
  margin: 50px auto 0;

  @media screen and (max-width: 992px) {
    height: 395px;
    margin: 35px auto 30px;
  }
  
  @media screen and (max-width: 768px) {
    max-width: 460px;
    height: 325px;
  }

  @media screen and (max-width: 576px) {
    max-width: unset;
    width: 306px;
    height: 215px;
    margin: 40px auto 0;
  }
  
`;

export const StyledImg = styled.img`
  width: ${({widthValue}) => widthValue ? widthValue : '100%'};
  
  @media screen and (max-width: 1200px) {
    display: block;
    margin: 0 auto;
  }
`;

export const StyledForm = styled.form`
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  
  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
`;

export const FormContent = styled.div`
  margin-right: 50px;

  @media screen and (max-width: 1200px) {
  }
  
  @media screen and (max-width: 992px) {
    margin-right: 35px;
  }

  @media screen and (max-width: 768px) {
    margin: 0 auto 40px;
  }
`;

export const FormSubtitle = styled.div`
  max-width: 445px;

  @media screen and (max-width: 992px) {
    margin: 0 auto;
  }
`;

export const StyledText = styled.div`
  font-size: 16px;
  color: ${colors.white};
  font-family: ${fonts.nunitoSans};
  line-height: 1.4;
  
  @media screen and (max-width: 992px) {
    text-align: center;
  }
`;

export const OrangeLink = styled.a`
  font-family: ${fonts.nunitoSans};
  font-size: 16px;
  line-height: 1.4;
  color: ${colors.orange};
  font-weight: 600;
  display: inline-block;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const BoldText = styled(StyledText)`
  font-weight: bold;
  display: inline-block;
`;

export const StyledFieldsWrapper = styled.div`
  width: 420px; 
  margin-top: 50px;
  
  @media screen and (max-width: 992px) {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }

  @media screen and (max-width: 768px) {
    max-width: 380px;
  }
`;

export const RequiredFieldsText = styled.p`
  font-size: 12px;
  font-family: ${fonts.nunitoSans};
  line-height: 1.9;
  color: ${colors.white};
`;

export const StyledLabel = styled.label`
  font-size: 16px;
  font-family: ${fonts.nunitoSans};
  line-height: 1.6;
  font-weight: 300;
  color: ${colors.white};

  @media screen and (max-width: 992px) {
    font-size: 14px;
  }
`;

export const StyledInput = styled(Field)`
  width: 100%;
  padding: 8px 15px;
  height: 45px;
  background-color: #3e3e3e;
  font-size: 13px;
  color: #cccccc;
  outline: none;
  border-width: 1px 1px 1px 1px;
  border-color: #212122;
  
  &:focus {
    border: 1px solid ${colors.white};
  }

  @media screen and (max-width: 992px) {
    padding: 4px 15px;
    height: 38px;
  }
`;

export const StyledError = styled.p`
  color: ${colors.red};
  font-family: ${fonts.nunitoSans};
  font-size: 14px;
  line-height: 1.6;
  margin-top: 10px;
  position: absolute;
  bottom: -27px;
  ${({textAlign}) => textAlign || 'left'}: 0;
  width: max-content;
  
  @media screen and (max-width: 1200px) {
    bottom: -23px;
  }

  @media screen and (max-width: 992px) {
    bottom: -20px;
    font-size: 11px;
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  
  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1200px) {
    margin-bottom: 20px;
  }
`;

export const InputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;

  @media screen and (max-width: 992px) {
    margin-bottom: 20px;
  }
`;

export const InputError = styled.div`
  margin-top: 3px;
  font-family: ${fonts.nunitoSans};
  font-size: 14px;
  color: ${colors.red};
  line-height: 1.6;
`;

export const SubmitButton = styled.button`
  font-family: ${fonts.robotoCondensed};
  color: ${colors.white};
  border: 1px solid ${colors.orange};
  font-size: 14px;
  line-height: 1.3;
  padding: 0 45px;
  min-height: 45px;
  min-width: 45px;
  background-color: transparent;
  outline: none;
  margin: 40px 0 0;
  display: block;
  
  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 992px) {
    margin: 40px auto 0;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const StyledLoader = styled.div`
  border: 3px solid ${colors.lightGrey}; 
  border-top: 3px solid ${colors.orange}; 
  border-radius: 50%;
  width: 25px;
  margin: 0 auto;
  height: 25px;
  animation: ${spin} 2s linear infinite;
`;

export const StyledTextarea = styled(Field)`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  padding: 8px 15px;
  height: 150px;
  background-color: #3e3e3e;
  font-size: 13px;
  color: #cccccc;
  outline: none;
  border-width: 1px 1px 1px 1px;
  border-color: #212122;
  
  &:focus {
    border: 1px solid ${colors.white};
  }
`;

export const MoreInfo = styled.div`
  margin: 100px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media screen and (max-width: 1200px) {
    margin: 60px auto 0;
  }

  @media screen and (max-width: 992px) {
    margin: 50px auto 0;
  }
`;

export const MoreInfoText = styled.p`
  font-family: ${fonts.nunitoSans};
  line-height: 1.4;
  font-weight: 600;
  font-size: 16px;
  color: ${colors.orange};
  margin-bottom: 20px;
`;

export const MoreInfoArrow = styled.div`
  border: solid ${colors.white};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 8px;
  transform: rotate(45deg);
`;
